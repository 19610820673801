import Vue from "vue";
import VueRouter from "vue-router";
import { configureRouter, routerOptions } from "@/libs/routerHelper";
import metaHelper from "@/libs/metaHelper";
import lodashGet from "lodash/get";
import lodashIsNil from "lodash/isNil";

const PAGE_TITLE_PREFIX = metaHelper.pageTitlePrefix || "Upgrade";

Vue.use(VueRouter);

const PATH_PREFIX = "/account";

/**
 * All routes defined in here must be guarded by Spring MVC controller for IdP configurations.
 * If a tenant has a specific IdP configure all of these routes should never be reached and any
 * routes configured must only be accessible if the IdP is null and using CarSaver as the default.
 */
const routes = [
    {
        path: PATH_PREFIX + "/register",
        name: "account-register",
        component: () => import(/* webpackChunkName: "profile-create" */ "./views/RegisterAccount"),
        meta: {
            gtm: "Account - Register",
            title: PAGE_TITLE_PREFIX + " - Account",
        },
    },
    {
        path: PATH_PREFIX + "/login",
        name: "account-login",
        component: () => import(/* webpackChunkName: "account-login" */ "./views/Login"),
        meta: {
            gtm: "Account - Login",
            title: PAGE_TITLE_PREFIX + " - Login",
        },
    },
    {
        path: PATH_PREFIX + "/address",
        name: "account-register-address",
        component: () => import(/* webpackChunkName: "account-register-address" */ "./views/RegisterAddress"),
        meta: {
            gtm: "Account Address",
            title: PAGE_TITLE_PREFIX + " - Address",
        },
    },
    {
        path: PATH_PREFIX + "/forgot-password",
        name: "account-forgot-password",
        component: () => import(/* webpackChunkName: "account-forgot-password" */ "./views/ForgotPassword"),
        meta: {
            gtm: "Forgot Password",
            title: PAGE_TITLE_PREFIX + " - Forgot Password",
        },
        props: (route) => {
            const email = route.params.email !== undefined ? route.params.email : "";
            return { email };
        },
    },
    {
        path: PATH_PREFIX + "/reset-password",
        name: "account-reset-password",
        props: (route) => ({
            token: route.query.token,
        }),
        component: () => import(/* webpackChunkName: "account-reset-password" */ "./views/ResetPassword"),
        beforeEnter: (to, from, next) => {
            if (lodashIsNil(to.query.token)) {
                next({ path: "/" });
            } else {
                next();
            }
        },
        meta: {
            gtm: "Reset Password",
            title: PAGE_TITLE_PREFIX + " - Reset Password",
        },
    },
    {
        path: PATH_PREFIX + "/register/ghost",
        name: "account-register-ghost",
        component: () => import(/* webpackChunkName: "account-register-ghost" */ "./views/GhostAccount.vue"),
        meta: {
            gtm: "Ghost Account - Register",
            title: PAGE_TITLE_PREFIX + " - Ghost Account",
        },
        props: (route) => {
            const redirectPath = lodashGet(route.query, "redirectPath", null);

            return { redirectPath };
        },
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
    ...routerOptions,
});

configureRouter(router, PATH_PREFIX);

export default router;
